import { Injectable } 							from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';


import { environment} from '../../environments/environment';

import { Gateway } from '../model/gateway';
import { GatewayType } from '../model/gatewayType';
import { UtilService } from './util.service';

@Injectable()
export class GatewaysService {

 	private gatewayUrl = environment.apiurl + '/location/getGateways';
 	private gatewayTypeUrl = environment.apiurl + '/location/getGatewayTypes';

 	public hasError:Subject<boolean> 	= new Subject<boolean>();
	public message:Subject<string> 		= new Subject<string>();
	public gateway_id:Subject<number> 	= new Subject<number>();

	private _gateways: BehaviorSubject<Gateway[]> = new BehaviorSubject<Gateway[]>( [] );
	public gateways: Observable<Gateway[]> 		= this._gateways.asObservable();

	private _gatewayTypes: BehaviorSubject<GatewayType[]> = new BehaviorSubject<GatewayType[]>( [] );
	public gatewayTypes: Observable<GatewayType[]> 		= this._gatewayTypes.asObservable();

	constructor (private util:UtilService, private router: Router) {}

	public getGateways(gId?:number,page?:number,recordsPerPage?:number): void {
		const payload: any = {};

		if(gId) payload.gateway_id = gId;
		if(page) payload.page = page;
		if(recordsPerPage) payload.recordsPerPage = recordsPerPage;

		this.util._getData( this.gatewayUrl, payload)
			.pipe(map(ret=>ret.data))
			.subscribe( gJSON =>{
				console.log("G JSON", gJSON);
				const gateways = gJSON
									.map( jsonobj => Gateway.fromJSON( jsonobj ));
				this._gateways.next( gateways );
			})
	}

	public getAllGateways(): Observable<Gateway[]> {
		return this.util._getData( this.gatewayUrl )
			.pipe(map(ret=>ret.data))
			.pipe(map( gJSON =>{
				console.log("G JSON", gJSON);
				return gJSON.map( jsonobj => Gateway.fromJSON( jsonobj ));
				// this._gateways.next( gateways );
			}))
	}

	public getGatewayTypes(): void {
		this.util._requestData( this.gatewayTypeUrl )
			.pipe(map(ret=>ret.data))
			.subscribe( gJSON =>{
				const types = gJSON
									.map( jsonobj => GatewayType.fromJSON( jsonobj ));
				this._gatewayTypes.next( types );
			})
	}

	// TODO: we are not using location_name in saveGateway. no need to send in "body". todo is to extract it out before making call.
	// TODO: group_name is not required too
	public saveGateway(body): void {
		let url = environment.apiurl + '/location/saveGateway';
		this.util._postData( url,body )
			.pipe(map(ret=>ret.data))
			.subscribe( 
							res=>{
									this.gateway_id.next(res.gateway_id);
									this.router.navigateByUrl('/administration/gateway/edit/' + res.gateway_id);
								},
							(err)=>{
								console.log('Error saving gateway'); 
								this.hasError.next(true); 
								this.message.next(err.message)
							},
							()=>{console.log('saving gateway completed');this.hasError.next(false); this.message.next('Gateway information is saved successfully.')}
						)
	}

	public deleteGateway(body): void {
		let url = environment.apiurl + '/location/deleteGateway';
		this.util._postData( url,body )
			.pipe(map(ret=>ret.qry))
			.subscribe( 
							res=>{ this.router.navigateByUrl('/administration/gateways'); },
							()=>{console.log('Error deleting gateway')},
							()=>{console.log('saving gateway completed');this.hasError.next(false); this.message.next('Gateway has been deleted successfully.')}
						)
	}

	
}
