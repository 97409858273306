import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, Params}  from '@angular/router';
import { Observable } from 'rxjs';
import {UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl, ReactiveFormsModule, FormArray } from '@angular/forms';

import { GatewaysService } from '../../services/gateways.service';
import { Gateway } from '../../model/gateway';
import { GatewayType } from '../../model/gatewayType';

import { LocationsService } from '../../services/locations.service';
import { Location } from '../../model/location';

import { GroupsService } from '../../services/groups.service';
import { Group } from '../../model/group';
import { Permissions } from '../../model/permissions';

@Component({
  selector: 'app-gateway-edit',
  templateUrl: './gateway-edit.component.html',
  styleUrls: ['./gateway-edit.component.css']
})
export class GatewayEditComponent implements OnInit {

	public permissions = new Permissions();
  	public model: any = {};
  	public hasError:boolean;
	public message:string;
	public type:string;

	gateway_id		= new UntypedFormControl(0, [ Validators.required ]);
	name 			= new UntypedFormControl('', [ Validators.required ]);
	location_name 	= new UntypedFormControl('');
	location_id 	= new UntypedFormControl('', [ Validators.required ]);
	mac 			= new UntypedFormControl('');
	status_cde 		= new UntypedFormControl('');
	typeid 			= new UntypedFormControl('');
	group_name 		= new UntypedFormControl('');
	group_id 		= new UntypedFormControl('', [ Validators.required ]);
	
	gatewayForm: UntypedFormGroup = this.builder.group({
												  gateway_id: 		this.gateway_id
												, name: 			this.name
												, location_name: 	this.location_name
												, location_id: 		this.location_id
												, mac: 				this.mac
												, status_cde: 		this.status_cde
												, typeid: 			this.typeid
												, group_name: 		this.group_name
												, group_id: 		this.group_id
											});

	public gateways: Gateway;
	public locations: Location[];
	public gatewayTypes:Observable<GatewayType[]>;
	groups:Observable<Group[]>;

	constructor( 
				private activatedRoute: ActivatedRoute, 
				private gatewaysService:GatewaysService, 
				private locationsService:LocationsService, 
				private groupsService: GroupsService, 
				private builder: UntypedFormBuilder,
				private router: Router ) { }

	ngOnInit() {
		if( !this.permissions.hasGatewaysPermission() && !(this.permissions.userType == 'A' || this.permissions.userType == 'B') ){
			this.router.navigateByUrl('devices');
		}
		let gId;
		this.activatedRoute.params.subscribe((params: Params) => {
		    gId = params['id'];
		    this.gatewaysService.getGateways(gId);
		  });

		this.gatewaysService.getGatewayTypes();
		this.gatewayTypes = this.gatewaysService.gatewayTypes

		this.locationsService.getLocations();
		this.locationsService.locations.subscribe(x=>this.locations = x);

		this.gatewaysService.gateways.subscribe(
													gts=> gts
															.filter( gt=> gt.gateway_id == gId )
															.map( g=>{
																console.log('g', g);
																		// this.setLocations(g.group_id);
																		this.type = g.type;
																		this.gateway_id 		= new UntypedFormControl( g.gateway_id);
																		this.name 				= new UntypedFormControl( g.name, [ Validators.required ] );
																		this.location_name 		= new UntypedFormControl( g.location_name );
																		this.location_id 		= new UntypedFormControl( g.location_id , [ Validators.required ]);
																		this.mac 				= new UntypedFormControl( g.mac );
																		this.status_cde 		= new UntypedFormControl( g.status_cde , [ Validators.required ]);
																		this.typeid 			= new UntypedFormControl( g.typeid );
																		this.group_name 		= new UntypedFormControl( g.group_name );
																		this.group_id 			= new UntypedFormControl( g.group_id  , [ Validators.required ]);

																		this.gatewayForm = this.builder.group({
																												  gateway_id: 		this.gateway_id
																												, name: 			this.name
																												, location_name: 	this.location_name
																												, location_id: 		this.location_id
																												, mac: 				this.mac
																												, status_cde: 		this.status_cde
																												, typeid: 			this.typeid
																												, group_name: 		this.group_name
																												, group_id: 		this.group_id
																											})
																	}
																)
												 );


		this.groupsService.getGroups();
		this.groups	= this.groupsService.groups;
	}

	setLocation(){
		// console.log(this.gatewayForm.value.location_id)
		this.gatewayForm.controls['location_id'].setValue('');
	}

	getLocations(){
		return this.locations.filter(x=>x.group_id === this.gatewayForm.value.group_id)
	}

	submitGroup(){
		this.hasError = false;
		this.message = '';
		this.gatewaysService.saveGateway(this.gatewayForm.value);
		this.gatewaysService.hasError.subscribe(x=>this.hasError=x);
		this.gatewaysService.message.subscribe(x=>this.message=x);
		this.gatewaysService.gateway_id.subscribe(x=> { 
													this.gatewayForm.controls['gateway_id'].setValue(x); 
												} );
	}

	fakeDelete(){
		return false;
	}

	deleteGateway(){		
		console.log( {'gateway_id': this.gateway_id.value});
		this.gatewaysService.deleteGateway( {'gateway_id': this.gateway_id.value} );
		this.gatewaysService.hasError.subscribe(x=>this.hasError=x);
		this.gatewaysService.message.subscribe(x=>this.message=x);
	}

	canEditCompany(){
		if( this.permissions.hasCompaniesPermission() ){
			return true;
		}else if(!this.permissions.hasCompaniesPermission() && this.gateway_id.value === 0){
			return true;
		}else{
			return false;
		}
	}
}
